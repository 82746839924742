import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import store from "@/store/store.js";
import app from "./app.vue";
import Meta from "vue-meta";
import "swiper/swiper-bundle.css";

import "@/style/index.scss";
import { CountUp } from "countup.js";
import preview from "vue-photo-preview";
import "vue-photo-preview/dist/vue-photo-preview";
import "vue-photo-preview/dist/skin.css";
var options = {
  fullscreenEl: false,
  closeEl: false,
  zoomEl: false,
  counterEl: false,
  arrowEl: false,
  clickToCloseNonZoomable: true
};
Vue.use(preview, options);
Vue.use(Meta);
Vue.use(ElementUI);
import Videojs from "video.js";
import "video.js/dist/video-js.css";
Vue.prototype.$video = Videojs;
Vue.prototype.$countUp = CountUp;
Vue.prototype.imgSuffix = "?x-oss-process=image/resize,m_lfit,w_300";
Vue.prototype.imgSuffixTime = "";
new Vue({
  el: "#app",
  router,
  store,
  data: {
    title: "中峪农业",
    keywords: "浙江中峪农业发展有限责任公司,中峪农业,道地药材",
    description:
      "浙江中峪农业发展有限责任公司（简称“中峪农业”），秉持以绿水青山就是金山银山的发展理念，传承发展中医药产业为己任的宗旨，以数字化改革和乡村振兴为初心，致力于在农业领域推动创新数字化发展，专注于道地药材从源头孵化与保护产业生态，带动地方就业、加工、仓储物流等，推动地方文旅、科研、产教研学、人才培养等多元化产业发展；同时积极探索药、风、光、零碳、能源产业带的同步推进。在集团内循环产业链赋能下，跨界融合部署，遵循国家政策，紧跟国家战略，以“数字经济新农业 乡村振兴新载体”的创新产业布局，致力于实现乡村产业振兴和多维推动共同富裕，打造数字化新农业的全新产业标杆。",
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "keywords",
          content: this.keywords,
        },
        {
          name: "description",
          content: this.description,
        },
      ],
    };
  },
  render: (h) => h(app),
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
});
