<template>
  <div style="min-width: 1900px">
    <div
      class="head"
      v-if="recommendList[headIndex] && recommendList[headIndex].newsCover"
      :style="
        'background-image: url(' +
          recommendList[headIndex].newsCover +
          imgSuffix +
          ');'
      "
    >
      <div class="head_minBg"></div>
      <div class="headTv ">
        <img class="hotImg" src="../../../static/v1.0/hotnew.png" alt="" />
        <div class="headBox">
          <div class="head_img">
            <!-- loop="false" -->
            <el-carousel
              arrow="never"
              :interval="5000"
              ref="headCarousel"
              @change="carouselC"
            >
              <el-carousel-item
                v-for="(item, index) in recommendList"
                :key="index"
              >
                <div
                  class="head_bg"
                  style="cursor: pointer"
                  @click="toDetails(item, index)"
                >
                  <img
                    src="../../../static/newImg/img_loading.png"
                    class="img_loading"
                    v-show="imgSuffix"
                  />
                  <div class="mask"></div>
                  <img :src="item.newsCover + imgSuffix" width="100%" />
                </div>
                <div class="head_item">
                  <!-- style="margin-bottom: 10px" -->
                  <div class="head_font">
                    {{ item.newsTitle }}
                  </div>
                  <div style="display: flex; opacity: 0.6; padding-top: 20px">
                    <div class="head_font1" style="margin-right: 30px">
                      {{ item.publishTime }}
                    </div>
                    <div class="head_font1">
                      发布单位：{{
                        item.publishUnit && item.publishUnit.companyName
                          ? item.publishUnit.companyName
                          : "中峪农业"
                      }}
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="cornerMarkBox">
            <div
              :class="{ ckcss: cssCk == index }"
              class="cornerMark"
              v-for="(item, index) in recommendList"
              :key="index"
              @click="cornerCM(index)"
            >
              {{ index + 1 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="middle moveTopNormal"  >
      <div class="imgbox"></div>
      <div class="titile" style="height: 420px">
        <img class="oneI" src="../../../static/v1.0/one.png" alt="" />
        <img class="twoI" src="../../../static/v1.0/two.png" alt="" />
        <img class="img" src="../../../static/v1.0/新·闻·中·心.png" alt="" />
        <span class="imgT">NEWS CENTER</span>
      </div>
      <div style="max-width: 1900px; margin: 0 auto">
        <div class="middle_new">
          <div class="middle_Left">
            <el-carousel
              :interval="5000"
              ref="middleCarousel"
              arrow="never"
              @change="carouselMid"
            >
              <!-- @click="toDetails(item, index)" -->
              <el-carousel-item
                :style="
                  `background-image: url(${
                    item.newsCover
                  });background-size: cover;`
                "
                v-for="(item, index) in middle_journalism"
                :key="index"
              >
                <img
                  src="../../../static/newImg/img_loading.png"
                  class="img_loading"
                  v-show="imgSuffix"
                />
                <div
                  style="min-width: 896px; height: 512.4px"
                  @click="toDetails(item, index)"
                ></div>
                <div class="mask" @click="toDetails(item, index)"></div>
                <div class="middle_item" @click="toDetails(item, index)">
                  <div class="middle_font">
                    {{ item.newsTitle }}
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <div class="cornerMarkBoxMid">
              <div
                :class="{ ckcss: midCk == item }"
                class="cornerMarkMid"
                v-for="(item, index) in middle_journalism"
                :key="index"
                @click="cornerMid(index + 1)"
              >
                <div style="color: #fff">{{ index + 1 }}</div>
              </div>
            </div>
          </div>
          <div class="middle_Rigth">
            <!-- style="color:#157b2f;" -->
            <div
              v-for="(item, index) in middle_journalism"
              @click="toDetails(item, index)"
              :key="index"
            >
              <div
                class="middle_Ritem moveTop1"
                @mouseover="mouseOverNews(index)"
              >
                <div class="middle_Rfont">{{ item.newsTitle }}</div>
                <div
                  style="display: flex; line-height: 16px; margin-bottom: 46px"
                >
                  <div class="minddle_time">
                    {{ item.publishTime }}
                  </div>
                  <div style="margin: 0 20px 0 29px">|</div>
                  <div class="minddle_danwie">
                    {{ item.newsIntroduction || item.newsTitle }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="middle_three">
          <el-carousel
            indicator-position="none"
            :autoplay="false"
            arrow="never"
            height="1700px"
            ref="newsTypeCarousel"
          >
            <el-carousel-item v-for="num in 1" :key="num">
              <div class="">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide home_module_8_module"
                    v-for="(arr, index) in 1"
                    :key="index"
                  >
                    <div
                      class="home_module_8_module_item"
                      v-for="(item, index2) in middle_journalism_three"
                      :key="index2"
                      @mouseout="tomouseout"
                      @mousemove="tomousemove(index2)"
                      @click="toDetails(item)"
                    >
                      <div
                        class="home_module_8_cover"
                        style="position: relative;"
                      >
                        <img
                          src="../../../static/newImg/img_loading.png"
                          class="img_loading"
                          v-show="imgSuffix"
                        />
                        <img :src="item.newsCover + imgSuffix" />
                      </div>
                      <div class="home_module_8_date">
                        <div>{{ item.publishTime }}</div>
                      </div>
                      <div class="home_module_8_title">
                        <div>{{ item.newsTitle }}</div>
                      </div>
                      <div class="home_module_8_more1">
                        <img
                          v-show="mousemoveIndex != index2"
                          src="../../../static/v1.0/L_b.png"
                          alt=""
                          srcset=""
                        />
                        <div v-show="mousemoveIndex == index2">
                          <img :src="introduction_bg1" class="one" alt="" />
                          <img
                            :src="introduction_bg1"
                            class="two"
                            style="margin: 0 3px"
                            alt=""
                          />
                          <img :src="introduction_bg1" class="three" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="home_module_8_content_right">
                <div class="home_module_8_content_dom">
                  <div
                    class="more moveTop1"
                    style="display: flex"
                    @click="toPage('/newsList', { id: num })"
                  >
                    查看更多
                    <img
                      class="more_img"
                      src="../../../static/v1.0/L_b.png"
                      style="
                width: 26px;
                height: 15px;
                margin-left: 10px;
                padding-top: 11px;
              "
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: center">
      <div class="block moveTopNormal">
        <div class="titile" style="height: 300px">
          <img
            class="oneI"
            style="top: 100px"
            src="../../../static/v1.0/one.png"
            alt=""
          />
          <img
            class="img"
            style="top: 110px"
            src="../../../static/v1.0/版·块·介·绍.png"
            alt=""
          />
          <span class="imgT" style="top: 185px">SECTION INTRODUCTION</span>
        </div>
        <div class="home_module_3">
          <div class="imgs">
            <!-- @click="imgIndex = 0" -->
            <div
              class="imgs_item"
              :class="imgIndex == 0 ? 'active' : ''"
              @click="MedicineSector"
              data-index="0"
              @mouseover="changeImgIndex(0)"
              :style="
                'background-image: url(' +
                  require('../../../static/v1.0/人参.jpg') +
                  ');'
              "
            >
              <div class="imgs_tips_item_tips">
                <div class="imgs_tips_item_title">药材版块</div>
                <div class="img_tips_item_centent_dom">
                  <div class="imgs_tips_item_centent">
                    我们专注于道地药材的种子种苗孵化培育、科学精细化种植、研发、加工与销售，
                    始终坚持在原产地种植，确保药材纯正品质。
                  </div>
                  <div class="imgs_tips_item_number_dom">
                    <div
                      class="imgs_tips_item_number"
                      @click.stop="jumpArea('/medicinal?id=1')"
                    >
                      <div class="imgs_tips_font">
                        {{ statisticInfo.yaocai.plantedNumber }}
                      </div>
                      <div class="imgs_tips_mid">种植面积(亩)</div>
                    </div>
                    <div class="imgs_tips_hengang"></div>
                    <div class="imgs_tips_item_number">
                      <div class="imgs_tips_font">
                        {{ statisticInfo.yaocai.projectPersonnel }}
                      </div>
                      <div class="imgs_tips_mid">工作人员(人)</div>
                    </div>
                    <div class="imgs_tips_hengang"></div>
                    <div class="imgs_tips_item_number">
                      <div class="imgs_tips_font">
                        {{ statisticInfo.yaocai.projectBaseNumber }}
                      </div>
                      <div class="imgs_tips_mid">基地数量(个)</div>
                    </div>
                    <div class="imgs_tips_hengang"></div>
                    <div class="imgs_tips_item_number">
                      <div class="imgs_tips_font">
                        {{ statisticInfo.yaocai.subordinateUnits }}
                      </div>
                      <div class="imgs_tips_mid">下属单位(家)</div>
                    </div>
                  </div>
                  <div class="imgs_tips_item_tips_img">
                    <div
                      class="imgs_tips_item_tips_f"
                      @click.stop="toPath('/dataCenter')"
                    >
                      数据中心
                    </div>
                    <img :src="introduction_bg" class="one" alt="" />
                    <img
                      :src="introduction_bg"
                      class="two"
                      style="margin: 0 3px"
                      alt=""
                    />
                    <img :src="introduction_bg" class="three" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="imgs_item"
              :class="imgIndex == 1 ? ' active' : ''"
              @click="EnergySector"
              data-index="1"
              @mouseover="changeImgIndex(1)"
              :style="
                'background-image: url(' +
                  require('../../../static/v1.0/绿地风车.jpg') +
                  ');'
              "
            >
              <div class="imgs_tips_item_tips">
                <div class="imgs_tips_item_title">能源版块</div>
                <div class="img_tips_item_centent_dom">
                  <div class="imgs_tips_item_centent">
                    能源版块是当今社会发展中的一个关键领域，其发展与利用对环境、经济和社会都有着深远的影响
                  </div>
                  <div class="imgs_tips_item_number_dom">
                    <div
                      class="imgs_tips_item_number"
                      @click.stop="jumpArea('/medicinal?id=4')"
                    >
                      <div class="imgs_tips_font">
                        {{ statisticInfo.nengyuan.plantedNumber }}
                      </div>
                      <div class="imgs_tips_mid">覆盖面积(亩)</div>
                    </div>
                    <div class="imgs_tips_hengang"></div>
                    <div class="imgs_tips_item_number">
                      <div class="imgs_tips_font">
                        {{ statisticInfo.nengyuan.projectPersonnel }}
                      </div>
                      <div class="imgs_tips_mid">工作人员(人)</div>
                    </div>
                    <div class="imgs_tips_hengang"></div>
                    <div class="imgs_tips_item_number">
                      <div class="imgs_tips_font">
                        {{ statisticInfo.nengyuan.projectBaseNumber }}
                      </div>
                      <div class="imgs_tips_mid">基地数量(个)</div>
                    </div>
                    <div class="imgs_tips_hengang"></div>
                    <div class="imgs_tips_item_number">
                      <div class="imgs_tips_font">
                        {{ statisticInfo.nengyuan.subordinateUnits }}
                      </div>
                      <div class="imgs_tips_mid">下属单位(家)</div>
                    </div>
                  </div>
                  <div class="imgs_tips_item_tips_img">
                    <div
                      class="imgs_tips_item_tips_f"
                      @click.stop="toPath('/dataCenter')"
                    >
                      数据中心
                    </div>

                    <img :src="introduction_bg" class="one" alt="" />
                    <img
                      :src="introduction_bg"
                      class="two"
                      style="margin: 0 3px"
                      alt=""
                    />
                    <img :src="introduction_bg" class="three" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="company moveTopNormal">
      <div class="titile" style="position: relative">
        <img
          style="position: absolute; top: -53%; width: 100%; z-index: -1"
          src="../../../static/v1.0/青山.png"
          alt=""
          srcset=""
        />
        <img class="oneI" src="../../../static/v1.0/one.png" alt="" />
        <img class="img" src="../../../static/v1.0/省·级·公·司.png" alt="" />
        <span class="imgT">PROVINCIAL LEVEL</span>
      </div>
      <div style="height: 740px; position: relative">
        <div class="examples">
          <div class="bannerBox">
            <div class="swiper-container1">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  id="swiperSlide1"
                  :data-item="item.companyId"
                  :style="`background: url(${item.companyImage})`"
                  v-for="(item, index) in treeData"
                  :key="index"
                >
                  <div
                    style="
                      width: 1206px;
                      display: flex;
                      justify-content: center;
                    "
                  >
                    <div class="mask"></div>
                    <div style="background: none; z-index: 2" class="moveTop2">
                      <div class="swiper_title" style="background: none">
                        {{ item.companyName }}
                      </div>
                      <div class="swiper_font" style="background: none">
                        {{ item.companyDesc }}
                      </div>
                      <div class="home_module_8_more"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="base moveTopNormal">
      <div class="base_bul">
        <div style="max-width: 1900px; margin: 0 auto">
          <div class="titile">
            <img class="oneI" src="../../../static/v1.0/one.png" alt="" />
            <img
              class="img"
              src="../../../static/v1.0/基·地·中·心.png"
              alt=""
            />
            <span class="imgT">BASE CENTER</span>
          </div>
          <div class="base_dom">
            <div
              :class="{ onbaseClass: item.id == onbase }"
              class="base_all_dom"
              v-for="(item, index) in baseList"
              :key="index"
            >
              <div class="base_all" @click="onbaseAll(index, item)">
                <img
                  v-if="item.id == onbase"
                  src="../../../static/v1.0/yun.png"
                  class="base_img"
                  alt=""
                />
                <div class="base_font">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="base_child">
            <div
              class="base_child_info_project"
              v-if="childProjectList.length > 0"
            >
              <div
                class="base_child_info_project_item moveTopNormal"
                v-for="item in childProjectList"
                :key="item.projectId"
                @click="jumpClick(item)"
              >
                <div class="base_child_info_project_item_img">
                  <img :src="item.projectImage" width="100%" />
                  <div class="base_child_title" style="">
                    <div
                      style="
                        bottom: 31px;
                        left: 16px;
                        font-family: 楷体;
                        position: absolute;
                      "
                    >
                      {{ item.projectName }}
                    </div>
                  </div>
                </div>
                <div
                  class="base_child_info_project_item_tips"
                  v-if="item.projectId != -1"
                >
                  <div
                    class="base_child_info_project_item_tips_left"
                    style="font-weight: bold"
                  ></div>
                  <div
                    class="base_child_info_project_item_tips_left"
                    style="
                      font-size: 20px;
                      line-height: 30px;
                      margin-top: 10px;
                      margin-bottom: 12px;
                    "
                  >
                    项目时间：{{ item.projectDate.split(",")[0] }} 至
                    {{ item.projectDate.split(",")[1] }}
                  </div>
                  <div
                    class="base_child_info_project_item_tips_left"
                    style="
                      font-size: 20px;
                      line-height: 30px;
                      margin-top: 12px;
                      margin-bottom: 12px;
                    "
                  >
                    所属单位：{{ item.projectUnit }}
                  </div>
                  <div
                    class="base_child_info_project_item_tips_left"
                    style="font-size: 20px; line-height: 30px"
                  >
                    <div class="base_child_info_project_item_tips_right">
                      {{ item.projectDesc }}
                    </div>
                  </div>
                  <div class="base_child_info_project_item_tips_more">
                    了解详情<img
                      src="../../../static/v1.0/21.png"
                      width="16px"
                      height="16px"
                    />
                  </div>
                  <div class="base_child_info_project_item_tips_line"></div>
                </div>
              </div>
            </div>

            <div
              class="base_child_info_project"
              v-if="childProjectList.length == 0"
              style="text-align: center;display: block;"
            >
              <img
                src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-19/dce89a55ac694fecba87dbe7f715a5af.png"
                width="700px"
                style="margin-top: 20px;"
              />
              <div
                style="width: 100%;font-weight: bold;font-size: 26px;color: #c9c9c9;line-height: 39px;"
              >
                更多基地正在开发中···
              </div>
            </div>

            <div
              class="more more2 moveTop1" v-if="childProjectList.length !== 0"
              style="position: relative;left: 0;width: 100%;transform: translate(0 , 0);margin: 0 auto;padding-bottom: 100px;"
              @click="more2Product"
            >
              查看更多
              <img
                class="more_img"
                src="../../../static/v1.0/L_b.png"
                style="width: 26px;height: 15px;margin-left: 10px;padding-top: 10px;vertical-align: top;"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="product moveTopNormal">
      <div class="product_tit">
        <div style="width: 1568px; margin: 0 auto">
          <div class="titile">
            <img class="oneI" src="../../../static/v1.0/one.png" alt="" />
            <img
              class="img"
              src="../../../static/v1.0/产·品·中·心.png"
              alt=""
            />
            <span class="imgT">PRODUCT CENTER</span>
          </div>
          <div class="product_font">
            中医中药在中国古老的大地上已经运用了几千年的历史，经过几千年的临床实践，证实了中国的中医中药无论是在治病上、在防病上，还是在养生上，都是确凿有效可行的。在西医未传入中国之前，我们的祖祖辈辈都用中医中药来治疗疾病，挽救了无数人的生命。
          </div>
          <div class="product_list">
            <!-- MedicineList -->
            <div
              class="product_dom"
              :style="`background: url(${item.img});background-size: cover;`"
              v-for="(item, index) in medicinalList"
              @click="jumpR(item)"
              :key="index"
            >
              <div class="mask"></div>
              <div class="product_dom_font">
                <div class="product_dom_title">{{ item.title }}</div>
                <div class="product_dom_center">{{ item.center }}</div>
                <div style="margin-top: 20px">
                  <img src="../../../static/v1.0/L.png" alt="" srcset="" />
                </div>
              </div>
            </div>
          </div>
          <div class="more moveTop1" style="display: flex" @click="moreProduct">
            查看更多
            <img
              class="more_img"
              src="../../../static/v1.0/L_b.png"
              style="
                width: 26px;
                height: 15px;
                margin-left: 10px;
                padding-top: 11px;
              "
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
      <div
        style="
          height: 85px;
          padding-bottom: 105px;
          width: 100%;
          background: #f7f7f7;
        "
      ></div>
    </div>
    <div class="duty moveTopNormal">
      <div class="titile" style="height: 380px">
        <img class="oneI" src="../../../static/v1.0/one.png" alt="" />
        <img class="img" src="../../../static/v1.0/社·会·责·任.png" alt="" />
        <span class="imgT" style="width: 100%; color: #000; top: 255px"
          >绿水青山 ｜ 国家双碳 ｜ 乡村振兴 ｜ 共同富裕</span
        >
      </div>
      <div style="height: 570px; background: #fff; position: relative">
        <div class="examples">
          <div style="heigth: 550px; overflow: hidden">
            <div class="bannerBox">
              <div class="swiper-container2">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    id="swiperSlide1"
                    :style="`background: url(${item.img})`"
                    v-for="(item, index) in banner"
                    :key="index"
                    :virtualIndex="indexC2"
                    @click="swiper_C2(index)"
                  ></div>
                </div>
              </div>
              <div
                ref="paginationBullets"
                class="swiper-pagination"
                slot="pagination"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 120px"></div>
    </div>
    <div class="foot moveTopNormal">
      <div class="foot_module_3">
        <div class="imgs">
          <div
            class="imgs_item"
            :class="imgIndexF == 0 ? ' active' : ''"
            @click="imgIndexF = 0"
            data-index="0"
            @mouseover="changeImgIndexF(0)"
            :style="
              'background-image: url(' +
                require('../../../static/v1.0/xianweijing.jpg') +
                ');'
            "
          >
            <div class="imgs_tips_item_tips">
              <div class="imgs_tips_item_tips_head">创新</div>
              <div class="imgs_tips_item_tips_text">
                我们专注于道地药材的种子种苗孵化培育、<br />科学精细化种植、研发、加工与销售。
              </div>
            </div>
          </div>
          <div
            class="imgs_item"
            :class="imgIndexF == 1 ? ' active' : ''"
            @click="imgIndexF = 1"
            data-index="1"
            @mouseover="changeImgIndexF(1)"
            :style="
              'background-image: url(' +
                require('../../../static/v1.0/谷物.jpg') +
                ');'
            "
          >
            <div class="imgs_tips_item_tips">
              <div class="imgs_tips_item_tips_head">中医</div>
              <div class="imgs_tips_item_tips_text">
                始终坚持在原产地种植，确保药材纯正品质，<br />将在全国范围内建立多个道地药材种植基地。
              </div>
            </div>
          </div>
          <div
            class="imgs_item"
            :class="imgIndexF == 2 ? ' active' : ''"
            @click="imgIndexF = 2"
            data-index="2"
            @mouseover="changeImgIndexF(2)"
            :style="
              'background-image: url(' +
                require('../../../static/v1.0/梯田.jpg') +
                ');'
            "
          >
            <div class="imgs_tips_item_tips">
              <div class="imgs_tips_item_tips_head">纯正</div>
              <div class="imgs_tips_item_tips_text">
                我们将积极拓展国际市场，将中华传统药材<br />文化推向世界舞台，让更多人了解和受益于中医药的神奇魅力。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import {
  getCompanyList,
  getNewsList,
  getProject,
  getStatistic,
  getSignature,
} from "@/api/index.js";
Swiper.use([Navigation, Pagination, Autoplay]);
export default {
  name: "home111",

  data() {
    return {
      companyName: [
        {
          name: "国家电力投资集团",
          url:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-18/331a9ccf00f142e0b92d9afcee41f190.png",
        },
        {
          name: "运达能源科技集团股份有限公司",
          url:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-18/cc6ab34dac7240f1800f026e30468113.png",
        },
      ],
      forIOS: false,
      headIndex: 0,
      cssCk: 0,
      midCk: 1,
      //新闻中心
      middle_journalism: [],
      middle_journalism_three: [],
      newsTypeIndex: 0,
      container3: null,
      newsType: 0,
      imgIndex: 0,
      imgIndexF: 0,
      bannerIndex: 0,
      bannerTxt: "手写的处方",
      introduction_bg: require("../../../static/v1.0/3.png"),
      introduction_bg1: require("../../../static/v1.0/lv_3.png"),
      banner: [
        {
          id: 1,
          img:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-04/5ab5700570334a7d8ede83ef59a1100d.jpg",
        },
        {
          id: 2,
          img:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-04/ae3bb3f56ec34cfc916cbf07dc253b3e.jpg",
        },
        {
          id: 3,
          img:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-04/369991e5b6064e84be431815f3b66141.jpg",
        },
        {
          id: 4,
          img:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-04/dad8a50ed2ad48f0a43a03caedf6a384.jpg",
        },
        {
          id: 5,
          img:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-04/9c2a81a1897e4581976ddc852ba738dc.jpg",
        },
      ],
      indexC: 0,
      indexC2: null,
      baseList: [
        {
          name: "全部基地",
          id: -1,
        },
        {
          name: "种植基地",
          id: 1,
        },
        {
          name: "种子基地",
          id: 0,
        },
        {
          name: "种苗基地",
          id: 2,
        },
        {
          name: "科研基地",
          id: 3,
        },
        {
          name: "能源基地",
          id: 4,
        },
        {
          name: "查看更多",
          id: 7,
        },
      ],
      childProjectList: [],
      ix: 0,
      recommendList: [{}],
      medicinalList: [
        {
          title: "关药-远志",
          id: 389,
          center:
            "中医中药在中国古老的大地上已经运用了几千年的历史，经过几千年的临床实践，证实了中国的中医中药无论是在治病上、在防病上，还是在养生上，都是确凿有效可行的。在西医未传入中国之前，我们的祖祖辈辈都用中医中药来治疗疾病，挽救了无数人的生命。",
          img: require("../../../static/image/远志.jpg"),
        },
        {
          title: "北药-龙骨",
          id: 390,
          center:
            "中医中药在中国古老的大地上已经运用了几千年的历史，经过几千年的临床实践，证实了中国的中医中药无论是在治病上、在防病上，还是在养生上，都是确凿有效可行的。在西医未传入中国之前，我们的祖祖辈辈都用中医中药来治疗疾病，挽救了无数人的生命。",
          img: require("../../../static/image/龙骨.jpg"),
        },
        {
          title: "怀药-法半夏",
          id: 415,
          center:
            "中医中药在中国古老的大地上已经运用了几千年的历史，经过几千年的临床实践，证实了中国的中医中药无论是在治病上、在防病上，还是在养生上，都是确凿有效可行的。在西医未传入中国之前，我们的祖祖辈辈都用中医中药来治疗疾病，挽救了无数人的生命。",
          img: require("../../../static/image/法半夏.jpg"),
        },
        {
          title: "维药-甘草",
          id: 396,
          center:
            "中医中药在中国古老的大地上已经运用了几千年的历史，经过几千年的临床实践，证实了中国的中医中药无论是在治病上、在防病上，还是在养生上，都是确凿有效可行的。在西医未传入中国之前，我们的祖祖辈辈都用中医中药来治疗疾病，挽救了无数人的生命。",
          img: require("../../../static/image/甘草.jpg"),
        },
        {
          title: "浙药-厚朴",
          id: 409,
          center:
            "中医中药在中国古老的大地上已经运用了几千年的历史，经过几千年的临床实践，证实了中国的中医中药无论是在治病上、在防病上，还是在养生上，都是确凿有效可行的。在西医未传入中国之前，我们的祖祖辈辈都用中医中药来治疗疾病，挽救了无数人的生命。",
          img: require("../../../static/image/厚朴.jpg"),
        },
        {
          title: "海药-牡蛎",
          id: 517,
          center:
            "中医中药在中国古老的大地上已经运用了几千年的历史，经过几千年的临床实践，证实了中国的中医中药无论是在治病上、在防病上，还是在养生上，都是确凿有效可行的。在西医未传入中国之前，我们的祖祖辈辈都用中医中药来治疗疾病，挽救了无数人的生命。",
          img: require("../../../static/image/牡蛎.jpg"),
        },
      ],
      mousemoveType: false,
      mousemoveIndex: null,
      onbase: -1,
      treeData: null,
      companyItem: {},
      statisticInfo: {
        yaocai: {},
        nengyuan: {},
      },
    };
  },
  created() {},
  mounted() {
    this.imgSuffixTime = setTimeout(() => {
      this.imgSuffix = "";
    }, 2000);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    this.getCompanyTop();
    this.getWXInfo();
  },

  methods: {
    getStatistic() {
      getStatistic(this.companyItem.companyId).then((res) => {
        const data = res.data;
        this.statisticInfo = {
          yaocai: {
            plantedNumber: data[0].plantedNumber + data[2].plantedNumber,
            projectBaseNumber:
              data[0].projectBaseNumber + data[2].projectBaseNumber,
            projectPersonnel:
              data[0].projectPersonnel + data[2].projectPersonnel,
            subordinateUnits:
              data[0].subordinateUnits + data[2].subordinateUnits,
          },
          nengyuan: {
            plantedNumber: data[1].plantedNumber + data[2].plantedNumber,
            projectBaseNumber:
              data[1].projectBaseNumber + data[2].projectBaseNumber,
            projectPersonnel:
              data[1].projectPersonnel + data[2].projectPersonnel,
            subordinateUnits:
              data[1].subordinateUnits + data[2].subordinateUnits,
          },
        };
      });
    },
    getChildProjectList() {
      this.childProjectList = [];
      getProject({
        companyId: this.PcompanyId,
        withChildCompany: 1,
        projectType: this.onbase == -1 ? null : this.onbase,
        current: 1,
        size: 4,
      }).then((res) => {
        if (res.data.list.length == 0) {
          this.childProjectList = [];
        } else {
          this.childProjectList = res.data.list;
        }
        this.$nextTick(() => {
          const arr = document.querySelectorAll(
            ".base_child_info_project_item"
          );
          arr.forEach((val) => {
            val.className = "base_child_info_project_item moveTopNormal";
            setTimeout(() => {
              val.className =
                "base_child_info_project_item moveTopNormal moveTopAnimation";
            }, 500);
          });
        });
      });
    },
    getCompanyTop() {
      getCompanyList({ current: 1, size: 4, pid: 0 }).then((res) => {
        const company = res.data.list[2];
        this.$store.commit("setCompany", company);
        this.companyItem = company;
        window.scrollTo({
          top: 0,
          left: 0,
        });
        this.getCompanyList();
        this.getList();
        this.getStatistic();
      });
    },
    mouseOverNews(index) {
      this.midCk = index + 1;
      this.$refs.middleCarousel.setActiveItem(index);
    },
    toPath(path) {
      this.$router.push(path);
    },
    onbaseAll(index, item) {
      if (index != 6) {
        this.onbase = item.id;
        this.getChildProjectList();
      } else {
        if (item.id == 7) {
          this.$router.push({
            path: "/medicinal",
          });
        }
      }
    },
    jumpArea(path) {
      this.$router.push({
        path: path,
      });
    },
    jumpArea1(path) {},
    toPage(path, item) {
      this.$router.push(path, item);
    },
    getList() {
      getNewsList({
        current: 1,
        size: 4,
        websiteId: 3,
        recommend: 1,
      }).then((res) => {
        this.recommendList = res.data.list;
      });
      getNewsList({
        current: 1,
        size: 12,
        websiteId: 3,
        self: 1,
      }).then((res) => {
        this.newsList = res.data.list;
        this.middle_journalism = res.data.list;
        this.middle_journalism = res.data.list.slice(0, 3);
        if (res.data.total > 9) {
          this.middle_journalism_three = [
            this.newsList[3],
            this.newsList[4],
            this.newsList[5],
            this.newsList[6],
            this.newsList[7],
            this.newsList[8],
            this.newsList[9],
            this.newsList[10],
            this.newsList[11],
          ];
        }
        setTimeout(() => {
          this.$nextTick(() => {
            this.container3 = new Swiper(".swiper-container3", {
              slidesPerView: 1,
              observer: true, //开启动态检查器，监测swiper和slide
              observeParents: true, //监测Swiper 的祖/父元素
              loop: false,
            });
            let that = this;
            let mySwiper = new Swiper(".swiper-container1", {
              direction: "horizontal", //滑动方向，可设置水平(horizontal)或垂直(vertical)。
              loop: true, // 设置为true 则开启loop模式
              touchRatio: 0.5, //触摸变慢
              slidesPerView: "auto", // 设置slider容器能够同时显示的slides数量(carousel模式)。类型：number or auto
              centeredSlides: true, // 设定为true时，active slide会居中，而不是默认状态下的居左。
              spaceBetween: 90, // 在slide之间设置距离（单位px）。
              on: {
                slideChangeTransitionEnd: function(e) {
                  that.indexC = e.realIndex;
                  that.PcompanyId = that.treeData[that.indexC].companyId;
                  that.getChildProjectList();
                },
                click(e) {
                  if (
                    e.realIndex == that.indexC &&
                    !e.clickedSlide.className.includes("swiper-slide-next") &&
                    !e.clickedSlide.className.includes("swiper-slide-prev")
                  ) {
                    that.swiper_C(that.treeData[e.realIndex]);
                    return;
                  } else if (
                    e.realIndex == that.indexC &&
                    !e.clickedSlide.className.includes("swiper-slide-next")
                  ) {
                    e.slidePrev();

                    // that.PcompanyId =
                    return;
                  } else if (
                    e.realIndex == that.indexC &&
                    !e.clickedSlide.className.includes("swiper-slide-prev")
                  ) {
                    e.slideNext();
                    return;
                  }
                },
              },
            });
            let mySwiper2 = new Swiper(".swiper-container2", {
              direction: "horizontal", //滑动方向，可设置水平(horizontal)或垂直(vertical)。
              loop: true, // 设置为true 则开启loop模式
              autoplay: {
                delay: 1500, // ms
                disableOnInteraction: false,
              },
              slidesPerView: "auto", // 设置slider容器能够同时显示的slides数量(carousel模式)。类型：number or auto
              centeredSlides: true, // 设定为true时，active slide会居中，而不是默认状态下的居左。
              spaceBetween: 40, // 在slide之间设置距离（单位px）。
              //   loopAdditionaSlider: 0, // loop模式下会在slides前后复制若干个slide,，前后复制的个数不会大于原总个数。
              slideToClickedSlide: true,
              pagination: {
                el: ".swiper-pagination",
                clickable: true,
              },
              navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              },
            });
          });
        }, 500);
      });
    },
    changeImgIndex(index) {
      let isPhone = this.IsPhone();
      if (this.imgIndex != index && isPhone) {
        this.ix = 0;
      } else if (
        this.imgIndex === index &&
        index === 0 &&
        this.imgIndex === 0 &&
        isPhone
      ) {
        this.$router.push({
          path: "/medicinalSector",
          query: { id: this.imgIndex },
        });
      } else if (
        this.imgIndex === index &&
        index === 1 &&
        this.imgIndex === 1 &&
        isPhone
      ) {
        this.$router.push({
          path: "/EnergySector",
          query: { id: this.imgIndex },
        });
      }
      this.imgIndex = index;
    },
    changeImgIndexF(index) {
      this.imgIndexF = index;
    },

    jumpClick(item) {
      if (item.projectId != -1) {
        this.$router.push({
          path: `/projectDetail?id=` + item.projectId,
        });
      }
    },
    cornerCM(index) {
      this.cssCk = index;
      this.headIndex = index;
      this.$refs.headCarousel.setActiveItem(index);
    },
    cornerMid(item) {
      this.midCk = item;
      this.$refs.middleCarousel.setActiveItem(item - 1);
    },
    carouselC(e) {
      this.headIndex = e;
      this.cssCk = e;
    },
    carouselMid(e) {
      this.midCk = e + 1;
    },
    swiper_C(item) {
      this.$store.commit("setCompany", item);
      this.$router.push({
        path: `/ProvincialCompany`,
      });
    },
    tomouseout() {
      this.mousemoveIndex = null;
    },
    tomousemove(index) {
      this.mousemoveType = true;
      this.mousemoveIndex = index;
    },
    swiper_C2(index) {
      this.indexC2 = index;
    },
    //设备信息
    IsPhone() {
      let info = navigator.userAgent;
      let isPhone = /mobile/i.test(info);
      return isPhone;
    },
    touchendSector() {},
    //药材
    async MedicineSector() {
      let isPhone = this.IsPhone();
      if (isPhone) {
        if (this.ix == 1) {
          this.$router.push({
            path: "/medicinalSector",
            query: { id: this.imgIndex },
          });
        }
        this.ix++;
      } else {
        this.ix = 0;
        this.$router.push({
          path: "/medicinalSector",
          // query: { id: this.imgIndex },
        });
      }
    },
    getCompanyList() {
      getCompanyList({ current: 1, size: 1000, pid: 3 }).then((res) => {
        this.treeData = res.data.list;
        this.PcompanyId = res.data.list[0].companyId;
        this.getChildProjectList();
      });
    },
    toDetails(item, index) {
      this.$router.push({
        path: `/newsListDetail?id=${item.newsId}&type=${
          item.websiteNames
        }&index=0`,
      });
    },
    //能源
    EnergySector() {
      if (this.ix == 2) {
        this.ix = 0;
      }
      let isPhone = this.IsPhone();
      if (isPhone) {
        if (this.ix == 1) {
          this.$router.push({
            path: "/EnergySector",
            query: { id: this.imgIndex },
          });
        }
        this.ix++;
      } else {
        this.$router.push({
          path: "/EnergySector",
          // query: { id: this.imgIndex },
        });
      }
    },
    changeNewsTypeIndex(index) {
      if (this.newsTypeIndex == 2 && index == 1) {
        return;
      } else if (this.newsTypeIndex == 0 && index == -1) {
        return;
      }
      this.newsTypeIndex += index;
      if (index > 0) {
        this.container3[this.newsType].slideNext();
      } else {
        this.container3[this.newsType].slidePrev();
      }
    },
    jumpR(item) {
      this.$router.push({
        path: `/medicineDetails?id=${item.id}&tabIndex`,
      });
    },
    getWXInfo() {
      const that = this;
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp;
        const nonceStr = res.data.noncestr;
        const signature = res.data.signature;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: "wxad8dd02ae97d307f", // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
        });
        wx.ready(function() {
          //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: "中峪农业", // 分享标题
            desc:
              "浙江中峪农业发展有限责任公司，自成立以来，一直以守护道地药材为初心，传承发扬中华传统中医药产业为己任。我们专注于道地药材的种子种苗孵化培育、科学精细化种植、研发、加工与销售，始终坚持在原产地种植，确保药材纯正品质，将在全国范围内建立多个道地药材种植基地，严格遵循地域特性、自然规律和药材生长习性，确保药材在最佳生长环境中茁壮成长，致力于为更多人提供高品质、纯天然的中药材，为传统中医药文化焕发新的活力。 中峪农业将始终秉承“传承经典、创新发展”的理念，不断提升道地药材的品质和效益。我们将积极拓展国际市场，将中华传统药材文化推向世界舞台，让更多人了解和受益于中医药的神奇魅力。", // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl:
              "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-29/e60fabe8d26d4650924f8087c8fc965c.jpg", // 分享图标
          };
          wx.updateAppMessageShareData(obj);
          wx.updateTimelineShareData(obj);
        });
      });
    },
    moreProduct() {
      this.$router.push({
        path: `/Specific_m`,
      });
    },
    more2Product() {
      this.$router.push({
        path: `/medicinal`+ (this.onbase == -1 ? '' : ('?id='+this.onbase)),
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.head {
  width: 100%;
  height: 940px;
  padding-top: 175px;
  background-repeat: no-repeat;
  background-size: cover;

  .head_minBg {
    width: 100%;
    min-width: 1900px;
    height: 1115px;
    background: rgba(86, 72, 42, 0.75);
    backdrop-filter: blur(20px);
    position: absolute;
    top: 0;
    left: 0;
  }

  .headTv {
    width: 1451px;
    height: 848px;
    position: relative;
    margin: 0 auto;
    background: url("../../../static/v1.0/frame.png");
  }

  .hotImg {
    position: absolute;
    top: -11px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
  }

  .headBox {
    padding-top: 21px;
    overflow: hidden;
    width: 1400px;
    height: 800px;
    margin: 0 auto;
    display: flex;
  }

  .head_img {
    min-width: 1400px;
    height: 800px;
    margin: 0 auto;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.13) 0%,
      rgba(0, 0, 0, 0.83) 100%
    );

    .head_bg {
      .mask {
        width: 1400px;
        height: 400px;
        bottom: 0;
        position: absolute;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.83) 100%
        );
      }
    }

    .head_item {
      position: absolute;
      bottom: 63px;
      left: 64px;

      .head_font {
        width: 1000px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // word-break: break-all;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 2;
        // height: 104px;
        font-weight: bold;
        font-size: 32px;
        color: #ffffff;
        line-height: 52px;
        letter-spacing: 1px;
        text-align: justify;
        font-style: normal;
      }

      .head_font1 {
        font-weight: 500;
        font-size: 24px;
        color: #ffffff;
        line-height: 36px;
        letter-spacing: 1px;
        text-align: left;
        font-style: normal;
        opacity: 0.9;
      }
    }
  }

  .cornerMarkBox {
    z-index: 8;
    position: absolute;
    width: 180px;
    display: flex;
    bottom: 64px;
    right: 66px;
    cursor: pointer;
    justify-content: flex-end;

    .cornerMark {
      width: 36px;
      height: 36px;
      color: #fff;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 36px;
      margin-left: 10px;

      background: rgba(255, 255, 255, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.45);
    }

    .ckcss {
      background: rgba(65, 47, 27, 0.8);
      font-weight: bold;
      font-size: 18px;
    }
  }

  ::v-deep .el-carousel__indicators--horizontal {
    display: none !important;
  }

  ::v-deep .el-carousel__container {
    height: 800px;
  }
}

.middle {
  background: url("../../../static/v1.0/版块bg.png");
  position: relative;
  min-width: 1900px;
  margin: 0 auto;
  background-size: cover;

  .imgbox {
    background: url("../../../static/v1.0/青山.png");
    width: 100%;
    // height: 1400px;
    height: 1000px;
    position: absolute;
    bottom: -50%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -99;
    background-position-y: -100px;
  }

  //   background-repeat: repeat-x;
  .titile {
    height: 350px;
    margin: 0 auto;

    .oneI {
      position: absolute;
      top: 150px;
      left: 50%;
      transform: translateX(-50%);
    }

    .twoI {
      position: absolute;
      top: 176px;
      left: 50%;
      transform: translateX(-50%);
    }

    .img {
      width: 350px;
      height: 58px;
      position: absolute;
      top: 166px;
      left: 50%;
      transform: translateX(-50%);
    }

    .imgT {
      position: absolute;
      top: 250px;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 400;
      font-size: 21px;
      color: #6d6d6d;
      line-height: 32px;
      letter-spacing: 25px;
      text-align: center;
      font-style: normal;
      text-transform: uppercase;
    }
  }

  .middle_new {
    display: flex;
    margin: 0 166px;
    justify-content: space-between;

    .middle_Left {
      .mask {
        min-width: 896px;
        height: 250px;
        bottom: 0;
        z-index: 10;
        position: absolute;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.83) 100%
        );
      }

      cursor: pointer;
      border: 4px solid #bdb1a3;
      min-width: 896px;
      height: 512.4px;
      position: relative;

      .cornerMarkBoxMid {
        right: 30px;
        bottom: 24px;
        z-index: 8;
        position: absolute;
        display: flex;

        .cornerMarkMid {
          cursor: pointer;
          width: 36px;
          height: 36px;
          font-weight: 500;
          line-height: 36px;
          text-align: center;
          font-size: 18px;
          // color: #fff;
          background: rgba(255, 255, 255, 0.2);
          border: 1px solid rgba(255, 255, 255, 0.7);
          // opacity: 0.7;
          margin: 0 4px;
        }

        .ckcss {
          background: rgba(65, 47, 27, 0.8);

          // background: #412f1b;
          font-weight: bold;
        }
      }

      .middle_item {
        min-width: 896px;
        height: 512.4px;

        .middle_font {
          font-family: kaiti;
          z-index: 11;
          position: absolute;
          bottom: 24px;
          margin-left: 29px;
          width: 638px;
          overflow: hidden;
          font-weight: 900;
          font-size: 28px;
          color: #ffffff;
          line-height: 42px;
          letter-spacing: 1px;
          text-align: left;
          font-style: normal;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .middle_Rigth {
      .middle_Ritem:hover {
        color: #157b2f !important;
      }

      overflow: hidden;
      height: 512.4px;

      .middle_Ritem {
        cursor: pointer;
        border-bottom: 1px solid #f0f0f0;
        margin-left: 50px;
        margin-top: 50px;
      }

      .middle_Rfont {
        width: 580px;
        margin-bottom: 19px;
        overflow: hidden;
        font-family: kaiti;
        font-weight: 900;
        font-size: 28px;
        line-height: 42px;
        letter-spacing: 1px;
        text-align: left;
        font-style: normal;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .minddle_time {
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 400;
        font-size: 18px;
        line-height: 16px;
        text-align: left;
        font-style: normal;
      }

      .minddle_danwie {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 20px;
        width: 359px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 400;
        font-size: 18px;
        line-height: 16px;
        text-align: left;
        font-style: normal;
      }
    }

    ::v-deep .el-carousel__indicators {
      display: none !important;
    }

    ::v-deep .el-carousel__container {
      height: 512.4px;
    }
  }

  .middle_three {
    display: flex;
    margin: 0 166px;
    // width: 1900px;
    margin-top: 144px;
    justify-content: space-between;

    .home_module_8_content_right {
      margin: 50px 0;

      .home_module_8_content_dom {
        display: flex;
        font-size: 24px;
        font-family: kaiti;
        align-items: center;
        justify-content: space-between;
      }

      .home_module_8_content_right_title {
        font-weight: bold;
        font-size: 35px;
        color: #000000;
        line-height: 42px;
      }

      .home_module_8_content_right_date {
        margin: 40px 0 100px 0;
      }

      .home_module_8_content_right_more {
        font-weight: bold;
        font-size: 44px;
        color: #000000;
        letter-spacing: 2px;
        margin-top: 30px;
        position: relative;
        width: fit-content;
        cursor: pointer;
      }

      .home_module_8_content_right_more::after {
        position: absolute;
        content: " ";
        bottom: -10px;
        left: 0;
        height: 2px;
        width: 100%;
        background: #2e2e2e;
      }

      .icon-left,
      .icon-right {
        border: 1px solid #1d1b19;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        cursor: pointer;
      }

      .icon-left {
        margin-right: 20px;
      }

      .nodata {
        color: #cbcbcb;
        border: 1px solid #cbcbcb;
      }
    }

    .home_module_8_module {
      display: flex;
      flex-wrap: wrap;

      .home_module_8_module_item {
        width: 30%;
        margin-bottom: 80px;
        margin-right: 50px;
        cursor: pointer;

        .home_module_8_cover {
          width: 100%;
          overflow: hidden;
          height: 250px;

          img {
            width: 100%;
            transition: all 0.2s;
          }
        }

        .home_module_8_date {
          width: 50%;
          margin-top: 14px;
          transition: all 0.2s;
          color: #211f1f;

          div {
            width: 100%;
            font-size: 16px;
            line-height: 24px;
          }
        }

        .home_module_8_title {
          width: 100%;
          margin-top: 10px;
          transition: all 0.2s;
          color: #211f1f;

          div {
            font-family: kaiti;
            width: 100%;
            font-weight: bold;
            font-size: 28px;
            line-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }

        .home_module_8_more1 {
          margin-top: 20px;

          .one {
            height: 18px;
            opacity: 0;
            /* 初始状态设置为不透明，因为我们想要从不透明开始淡出 */
            animation: fadeInFadeOut 1.5s ease-in-out 0s infinite;
          }

          .two {
            height: 18px;
            opacity: 0;
            /* 初始状态设置为不透明，因为我们想要从不透明开始淡出 */
            animation: fadeInFadeOut 1.5s ease-in-out 0.4s infinite;
          }

          .three {
            height: 18px;
            opacity: 0;
            /* 初始状态设置为不透明，因为我们想要从不透明开始淡出 */
            animation: fadeInFadeOut 1.5s ease-in-out 0.8s infinite;
          }

          @keyframes fadeInFadeOut {
            0%,
            100% {
              opacity: 0;
            }

            /* 动画开始和结束时都是透明的 */
            50% {
              opacity: 1;
            }

            /* 动画中间是不透明的 */
          }

          .home_module_8_more1::after {
            content: "";
            display: block;
            width: 28px;
            height: 12px;
            background-image: url("../../../static/v1.0/L_b.png");
            background-repeat: no-repeat;
            background-size: contain;
            margin-top: 34px;
          }
        }
      }

      .home_module_8_module_item:last-child {
        margin-right: 0;
      }

      .home_module_8_module_item:hover {
        img {
          transform: scale(1.1);
        }

        .home_module_8_date {
          color: #157b2f;
        }

        .home_module_8_title {
          color: #157b2f;
        }

        .home_module_8_more::after {
          width: 46px;
          background-image: url("../../../static/newImg2/more.png");
        }
      }
    }

    .middle_dom {
      width: 510px;

      .middle_img {
        width: 510px;
        height: 292px;
        background: skyblue;
      }

      .middle_time {
        margin-top: 32px;
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 16px;
        color: #211f1f;
        line-height: 24px;
        text-align: left;
        font-style: normal;
      }

      .middle_data {
        height: 84px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-weight: 900;
        font-size: 28px;
        color: #000000;
        line-height: 42px;
        letter-spacing: 1px;
        text-align: left;
        font-style: normal;
      }
    }

    ::v-deep .el-carousel__container {
      height: 800px;
      width: 1568px;
    }
  }
}

.block {
  min-width: 1900px;
  margin: 0 160px;
  position: relative;

  .titile {
    height: 250px;
    margin: 0 auto;

    .oneI {
      position: absolute;
      top: 40px;
      left: 50%;
      transform: translateX(-50%);
    }

    .twoI {
      position: absolute;
      top: 176px;
      left: 50%;
      transform: translateX(-50%);
    }

    .img {
      width: 350px;
      height: 58px;
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
    }

    .imgT {
      position: absolute;
      top: 150px;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 400;
      font-size: 21px;
      color: #6d6d6d;
      line-height: 32px;
      letter-spacing: 25px;
      text-align: center;
      font-style: normal;
      text-transform: uppercase;
    }
  }

  .home_module_3 {
    position: relative;
    // width: 100%;
    // width: 1900px;
    margin: 0 166px;
    // margin-top: 25px;

    .imgs {
      width: 100%;
      height: 788px;
      overflow: hidden;
      display: flex;
      border-radius: 10px;
      justify-content: space-between;

      .imgs_item {
        // opacity: 0;
        position: relative;
        transition: all 0.6s linear;
        width: 18%;
        flex: 1 1 18%;
        object-fit: cover;
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: cover;

        .imgs_tips_item_tips {
          transition: all 0.3s linear;
          opacity: 1;

          .imgs_tips_item_title {
            font-family: 楷体;
            transition: all 0.3s linear;
            margin-top: 83px;
            margin-left: 51px;
            font-weight: 600;
            font-size: 73px;
            color: #ffffff;
            line-height: 102px;
            letter-spacing: 5px;
            text-align: left;
            font-style: normal;
          }

          .imgs_tips_item_centent {
            opacity: 0.58;
            width: 720px;
            margin-top: 12px;
            margin-bottom: 51px;
            transition: all 0.3s linear;
            font-weight: 400;
            font-size: 20px;
            color: #ffffff;
            line-height: 37px;
            text-align: left;
            font-style: normal;
          }

          .imgs_tips_item_tips_img {
            display: flex;
            position: absolute;
            bottom: 139px;
            left: 143px;
            align-items: center;

            .imgs_tips_item_tips_f {
              width: 154px;
              margin-right: 17px;
              font-weight: 500;
              font-size: 37px;
              color: #ffffff;
              line-height: 54px;
              letter-spacing: 1px;
              text-align: center;
            }

            .one {
              height: 18px;
              opacity: 0;
              /* 初始状态设置为不透明，因为我们想要从不透明开始淡出 */
              animation: fadeInFadeOut 1.5s ease-in-out 0s infinite;
            }

            .two {
              height: 18px;
              opacity: 0;
              /* 初始状态设置为不透明，因为我们想要从不透明开始淡出 */
              animation: fadeInFadeOut 1.5s ease-in-out 0.4s infinite;
            }

            .three {
              height: 18px;
              opacity: 0;
              /* 初始状态设置为不透明，因为我们想要从不透明开始淡出 */
              animation: fadeInFadeOut 1.5s ease-in-out 0.8s infinite;
            }

            @keyframes fadeInFadeOut {
              0%,
              100% {
                opacity: 0;
              }

              /* 动画开始和结束时都是透明的 */
              50% {
                opacity: 1;
              }

              /* 动画中间是不透明的 */
            }
          }

          .img_tips_item_centent_dom {
            opacity: 0;
          }

          .imgs_tips_item_number_dom {
            transition: all 0.3s linear;

            display: flex;
          }

          .imgs_tips_hengang {
            transition: all 0.3s linear;
            width: 1px;
            height: 67px;
            background: #ffffff;
            opacity: 0.32;
            margin: 0 53px;
            margin-top: 20px;
          }

          .imgs_tips_item_number {
            transition: all 0.3s linear;

            .imgs_tips_font {
              transition: all 0.3s linear;
              font-weight: bold;
              font-size: 47px;
              color: #ffffff;
              line-height: 55px;
              letter-spacing: 1px;
              text-align: center;
              font-style: normal;
            }

            .imgs_tips_mid {
              transition: all 0.3s linear;
              font-weight: 400;
              font-size: 13px;
              color: #ffffff;
              line-height: 21px;
              font-style: normal;
              margin-top: 12px;
              opacity: 0.6;
            }
          }
        }
      }
    }

    .active {
      width: 64% !important;
      flex: 1 1 64% !important;

      .imgs_tips_item_title {
        margin-left: 128px !important;
      }

      .imgs_tips_item_centent {
        margin-left: 128px !important;
      }

      .imgs_tips_item_number_dom {
        margin-left: 128px !important;
      }

      .img_tips_item_centent_dom {
        opacity: 1 !important;
      }

      .imgs_tips_item_tips_head {
        margin: 80px 150px 20px 150px !important;
      }

      .imgs_tips_item_tips_text {
        margin: 80px 150px 20px 150px !important;
        width: 500px;
        opacity: 1 !important;
        visibility: visible;
      }
    }
  }
}

.company {
  min-width: 1900px;
  margin: 0 auto;

  position: relative;

  .titile {
    height: 350px;
    margin: 0 auto;

    .oneI {
      position: absolute;
      top: 150px;
      left: 50%;
      transform: translateX(-50%);
    }

    .twoI {
      position: absolute;
      top: 176px;
      left: 50%;
      transform: translateX(-50%);
    }

    .img {
      width: 350px;
      height: 58px;
      position: absolute;
      top: 166px;
      left: 50%;
      transform: translateX(-50%);
    }

    .imgT {
      position: absolute;
      top: 250px;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 400;
      font-size: 21px;
      color: #6d6d6d;
      line-height: 32px;
      letter-spacing: 25px;
      text-align: center;
      font-style: normal;
      text-transform: uppercase;
    }
  }

  ::v-deep .el-carousel__indicators {
    display: none;
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

//
.examples {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  //   background-color: #fff;
  font-size: 14px;

  .bannerBox {
    height: 550px;
  }

  .mask {
    width: 1400px;
    height: 400px;
    bottom: 0;
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(8, 69, 33, 0.83) 100%
    );
  }

  // .swiper-wrapper :nth-child(2n) {
  //   background-color: skyblue;
  // }
  // .swiper-wrapper :nth-child(2n + 1) {
  //   background-color: pink;
  // }
  // .swiper-slide:hover {
  //   transform: scale(1.1);
  // }
  .tips {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 46px;
    background-color: #b5654d;
    text-align: center;

    img {
      width: 12px;
      height: 14px;
      position: absolute;
      top: 7.5px;
      left: 12vw;
      color: #fff;
    }

    p {
      width: 240px;
      text-align: left;
      color: #fff;
      font-size: 12px;
      position: absolute;
      left: 18vw;
      top: 7px;
      line-height: 16px;
    }
  }
}

.bannerTxt {
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #666666;
  font-size: 15px;
}

.bannerIndex {
  line-height: 40px;
  text-align: center;
  color: #666666;
  font-size: 15px;
  text-align: center;

  span {
    color: #b5654d;
  }
}

.swiper_title {
  font-family: 楷体;
  font-weight: 900;
  font-size: 54px;
  color: #ffffff;
  line-height: 42px;
  letter-spacing: 1px;
  text-shadow: 0px 2px 31px rgba(21, 123, 47, 0.18);
  text-align: center;
  margin-top: 300px;
}

.swiper-slide-prev {
  .swiper_font {
    opacity: 0;
  }

  .swiper_title {
    margin-top: 550px;
  }
}

.swiper-slide-next {
  .swiper_font {
    opacity: 0;
  }

  .swiper_title {
    margin-top: 550px;
  }
}

.swiper_font {
  margin-top: 35px;
  width: 847px;
  height: 93px;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  // 只要超过宽度就换行，不论中文还是英文
  // word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-height: 31px;
  text-shadow: 0px 2px 31px rgba(21, 123, 47, 0.18);
  text-align: left;
}

.home_module_8_more {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.home_module_8_more::after {
  content: "";
  display: block;
  width: 28px;
  height: 12px;
  background-image: url("../../../static/v1.0/L.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 34px;
}

.swiper-container1 {
  // width: 1900px;
  height: 740px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 25px;
  // .swiper-slide {
  //   transform: scale(0.99);
  // }
  // .swiper-slide:hover {
  //   transform: scale(1);
  //   border-radius: 25px;
  // }
}

.swiper-container1 .swiper-wrapper .swiper-slide {
  border-radius: 15px;
  width: 1206px;
  background-size: cover !important;
  overflow: hidden;
  cursor: pointer;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-container1 .swiper-wrapper .swiper-slide img {
  width: 100%;
  height: 380px;
  border-radius: 5px;
}

.swiper-container1 .swiper-wrapper .swiper-slide-prev,
.swiper-container1 .swiper-wrapper .swiper-slide-next {
  border-radius: 25px;
  height: 680px !important;
  margin-top: 20px;
}

.swiper-container1 .swiper-wrapper .swiper-slide-prev img,
.swiper-container1 .swiper-wrapper .swiper-slide-next img {
  width: 100%;
  height: 100%;
}

.base {
  position: relative;
  min-width: 1900px;
  margin: 0 auto;
  background: url("../../../static/v1.0/山.png");
  background-repeat: no-repeat;
  background-size: cover;

  .base_bul {
    margin: 0 160px;

    .titile {
      height: 350px;
      margin: 0 auto;

      .oneI {
        position: absolute;
        top: 150px;
        left: 50%;
        transform: translateX(-50%);
      }

      .twoI {
        position: absolute;
        top: 176px;
        left: 50%;
        transform: translateX(-50%);
      }

      .img {
        width: 350px;
        height: 58px;
        position: absolute;
        top: 166px;
        left: 50%;
        transform: translateX(-50%);
      }

      .imgT {
        position: absolute;
        top: 250px;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 400;
        font-size: 21px;
        color: #6d6d6d;
        line-height: 32px;
        letter-spacing: 25px;
        text-align: center;
        font-style: normal;
        text-transform: uppercase;
      }
    }

    .base_dom {
      width: 1550px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .base_all_dom {
        background: #f2f2f2;
        color: #4b4b4b;
        cursor: pointer;
      }

      .base_all_dom:hover {
        background-color: rgba(11, 91, 32, 0.2);
      }

      .onbaseClass {
        background-color: #0b5b20 !important;
        border-radius: 6px;
        color: #ffffff !important;
      }

      .base_all {
        width: 200px;
        height: 82px;
        line-height: 82px;
        display: flex;
        justify-content: center;
        position: relative;
        align-items: center;
        border-radius: 6px;

        .base_img {
          position: absolute;
          right: 0;
        }

        .base_font {
          font-family: 楷体;
          font-weight: 400;
          font-size: 28px;

          line-height: 42px;
          letter-spacing: 2px;
          text-align: left;
        }
      }
    }

    .base_dom :nth-child(n) {
      // margin-left: 33px;
    }

    .base_dom :nth-child(1) {
      margin-left: 0px;
    }

    .base_child {
      width: 1550px;
      margin: 0 auto;

      .base_child_info_project {
        margin-top: 70px;
        padding-bottom: 100px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .base_child_info_project_item:nth-child(2n-1) {
          margin-right: 50px;
          // background: red !important;
        }

        .base_child_info_project_item {
          cursor: pointer;
          // margin-right: 25px;
          // width: 49%;
          margin-top: 30px;

          .base_child_info_project_item_img {
            position: relative;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            width: 750px;
            height: 362px;

            .base_child_title {
              height: 125px;
              line-height: 125px;
              width: 100%;
              background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.03) 0%,
                rgba(0, 0, 0, 0.83) 100%
              );
              bottom: 0;
              font-family: 楷体;
              position: absolute;
              font-weight: 900;
              font-size: 36px;
              color: #ffffff;
              line-height: 42px;
              letter-spacing: 1px;
              // text-align: center;
            }

            overflow: hidden;

            img {
              display: block;
              border-radius: 5px;
              transition: all 1s;
            }
          }

          .base_child_info_project_item_tips {
            //  border-top-right-radius: 10px;
            // border-top-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            padding: 24px;
            max-width: 702px;
            background-color: #fff;
            box-shadow: 0px 2px 11px 0px rgba(53, 74, 50, 0.07);
            position: relative;

            .base_child_info_project_item_tips_left {
              font-size: 24px;
              font-weight: 400;
              // color: #000;
              line-height: 40px;
            }

            .base_child_info_project_item_tips_right {
              font-size: 20px;
              // color: #000;
              line-height: 30px;
              font-weight: 400;
              height: 55px;
              overflow: hidden;
              text-overflow: ellipsis;
              // 只要超过宽度就换行，不论中文还是英文
              word-break: break-all;
              //最多展示两行
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }

            .base_child_info_project_item_tips_more {
              width: 500px;
              margin-top: 30px;
              font-size: 12px;
              font-weight: 600;
              color: #157b2f;
              // color: #595959;
              line-height: 20px;
              display: flex;
              align-items: center;

              img {
                vertical-align: middle;
                margin-left: 10px;
                transition: all 0.5s ease-out;
              }
            }

            .base_child_info_project_item_tips_line {
              width: 0;
              height: 2px;
              background-color: #1e9409;
              transition: all 1s;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
        }

        .base_child_info_project_item:hover {
          .base_child_info_project_item_img img {
            transform: scale(1.1);
          }

          .base_child_info_project_item_tips_line {
            width: 100%;
          }
        }

        .base_child_info_project_item_only {
          display: flex;
          margin-top: 20px;
          cursor: pointer;

          .base_child_info_project_item_img {
            width: 750px;
            height: 362px;
            overflow: hidden;

            img {
              display: block;
            }
          }

          .base_child_info_project_item_tips {
            width: calc(100% - 590px - 48px);
            padding: 24px;
            background-color: #fff;
            box-shadow: 0px 2px 11px 0px rgba(53, 74, 50, 0.07);

            .base_child_info_project_item_tips_left {
              font-size: 20px;
              color: #000;
              line-height: 40px;
            }

            .base_child_info_project_item_tips_right {
              margin-top: 12px;
              font-size: 16px;
              font-weight: 400;
              color: #000;
              line-height: 27px;
              height: 85px;
              overflow: hidden;
            }

            .base_child_info_project_item_tips_more {
              width: 500px;
              margin-top: 30px;
              font-size: 12px;
              font-weight: 600;
              color: #157b2f;
              line-height: 20px;
              display: flex;
              align-items: center;

              img {
                vertical-align: middle;
                margin-left: 10px;
                transition: all 0.5s ease-out;
              }
            }
          }
        }
      }
    }
  }
}

.product {
  background: url("../../../static/v1.0/花.png");
  min-width: 1900px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: cover;

  .product_tit {
    margin: 0 160px;

    .titile {
      height: 350px;
      position: relative;
      margin: 0 auto;

      .oneI {
        position: absolute;
        top: 150px;
        left: 50%;
        transform: translateX(-50%);
      }

      .twoI {
        position: absolute;
        top: 176px;
        left: 50%;
        transform: translateX(-50%);
      }

      .img {
        width: 350px;
        height: 58px;
        position: absolute;
        top: 166px;
        left: 50%;
        transform: translateX(-50%);
      }

      .imgT {
        position: absolute;
        top: 250px;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 400;
        font-size: 21px;
        color: #6d6d6d;
        line-height: 32px;
        letter-spacing: 25px;
        text-align: center;
        font-style: normal;
        text-transform: uppercase;
      }
    }

    .product_font {
      // margin-top: 50px;
      padding-bottom: 88px;
      font-family: 楷体;
      font-weight: 400;
      font-size: 20px;
      color: #060606;
      line-height: 42px;
      text-align: center;
      font-style: normal;
    }

    .product_list {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 80px;

      .product_dom:hover {
        .product_dom_font {
          transform: translateY(0);
          transition: all 1s;
        }
      }

      .product_dom {
        // filter: grayscale(80%);
        cursor: pointer;
        border-radius: 10px;
        position: relative;
        background: pink;
        margin-bottom: 40px;
        width: 512px;
        height: 384px;
        flex: 0 0 31.9%;
        /* 每个项目占据一行宽度的1/3 */
        overflow: hidden;

        .mask {
          width: 512px;
          height: 384px;
          position: absolute;
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.13) 0%,
            rgba(0, 0, 0, 0.83) 100%
          );
        }

        .product_dom_font {
          position: absolute;
          bottom: 40px;
          left: 38px;
          transform: translateY(230px);

          .product_dom_title {
            margin-bottom: 33px;
            font-weight: bold;
            font-size: 26px;
            color: #ffffff;
            line-height: 39px;
            // text-align: center;
            font-style: normal;
          }

          .product_dom_center {
            width: 400px;
            font-weight: bold;
            font-size: 14px;
            color: #ffffff;
            line-height: 29px;
            text-align: left;
            font-style: normal;
          }
        }
      }

      .product_dom:nth-child(2) {
        margin: 0 32px;
        background: #157b2f;
      }

      .product_dom:nth-child(5) {
        margin: 0 32px;
        background: red;
      }
    }
  }
}
.more {
  font-family: 楷体;
  // margin-top: 85px;
  cursor: pointer;
  font-weight: 400;
  position: absolute;
  left: 50%;
  font-size: 36px;
  transform: translateX(-50%);
  color: #060606;
  width: 200px;
  // line-height: 10px;
  text-align: center;
  font-style: normal;
}
.more:hover {
  text-decoration-line: underline;
  transition: all 0.1s linear 0s;
  transform: translate(-50%, -10px);
}
.more2:hover {
  text-decoration-line: underline;
  transition: all 0.1s linear 0s;
  transform: translate(0, -10px) !important;
}

.duty {
  min-width: 1900px;
  margin: 0 auto;

  .titile {
    height: 350px;
    position: relative;
    margin: 0 auto;

    .oneI {
      position: absolute;
      top: 150px;
      left: 50%;
      transform: translateX(-50%);
    }

    .twoI {
      position: absolute;
      top: 176px;
      left: 50%;
      transform: translateX(-50%);
    }

    .img {
      width: 350px;
      height: 58px;
      position: absolute;
      top: 166px;
      left: 50%;
      transform: translateX(-50%);
    }

    .imgT {
      position: absolute;
      top: 250px;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 400;
      font-size: 21px;
      color: #6d6d6d;
      line-height: 32px;
      letter-spacing: 25px;
      text-align: center;
      font-style: normal;
      text-transform: uppercase;
    }
  }
}

.swiper-container2 {
  width: 100%;
  height: 450px;
}

.swiper-container2 .swiper-wrapper .swiper-slide {
  border-radius: 15px;
  background-size: cover !important;
  width: 640px;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-container2 .swiper-wrapper .swiper-slide img {
  width: 100%;
  height: 380px;
  border-radius: 5px;
}

.swiper-container2 .swiper-wrapper .swiper-slide-prev,
.swiper-container2 .swiper-wrapper .swiper-slide-next {
  border-radius: 25px;
  height: 450px !important;
  //   margin-top: 20px;
}

.swiper-container2 .swiper-wrapper .swiper-slide-prev img,
.swiper-container2 .swiper-wrapper .swiper-slide-next img {
  width: 100%;
  height: 100%;
}

.swiper-pagination {
  margin-top: 75px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

::v-deep .swiper-pagination-bullet {
  width: 120px;
  height: 6px;
  border-radius: 0;
  background: #ebebeb;
  margin: 0 8px;
}

::v-deep .swiper-pagination-bullet-active {
  background: #157b2f;
}

.foot {
  min-width: 1900px;
  margin: 0 auto;

  .foot_module_3 {
    position: relative;
    width: 100%;

    .imgs {
      width: 100%;
      height: 788px;
      overflow: hidden;
      display: flex;
      justify-content: space-between;

      .imgs_item {
        // opacity: 0;
        transition: all 0.6s linear;
        width: 18%;
        flex: 1 1 18%;
        object-fit: cover;
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: cover;

        .imgs_tips_item_tips {
          margin: 0 auto;
          font-size: 21px;
          color: #ffffff;
          line-height: 38px;
          letter-spacing: 2px;

          .imgs_tips_item_tips_head {
            font-family: kaiti;
            margin: 80px 40px 20px 40px;
            color: #ffffff;
            line-height: 84px;
            font-weight: 900;
            letter-spacing: 4px;
            font-size: 83px;
            transition: all 0.3s 0.3s linear;
          }

          .imgs_tips_item_tips_text {
            opacity: 0;
            width: 600px;
            margin: 80px 40px 20px 40px;
            transition: all 0.3s 0.3s linear;
            font-weight: 400;
            font-size: 24px;
            color: #ffffff;
            line-height: 44px;
            letter-spacing: 1px;
            text-align: left;
            font-style: normal;
            letter-spacing: 0.2em;
          }
        }
      }
    }

    .active {
      width: 64% !important;
      flex: 1 1 64% !important;

      .imgs_tips_item_tips_head {
        margin: 80px 150px 20px 150px !important;
      }

      .imgs_tips_item_tips_text {
        margin: 80px 150px 20px 150px !important;
        width: 500px;
        opacity: 1 !important;
        visibility: visible;
      }
    }
  }
}

.bg_black {
  background-color: #000;
  color: #ffffff;
}
.font1 {
  height: 101px;
  width: 1568px;
  margin: 0 auto;
  background: #000;
  overflow: hidden;
  display: flex;

  .font_dom {
    z-index: 10;
    // width: 200px;
    height: 100px;
    display: flex;
    align-items: center;
    // margin-right: 25px;
  }

  .font_for {
    display: flex;
  }
}
</style>
